import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmBody from '../../components/ConfirmBody';
import ViewAllPlansButton from '@components/Miscellaneous/ViewAllPlansButton';
import MyBoardmakerButton from '@components/Miscellaneous/MyBoardmakerButton';
import ProtectedPage from '@components/ProtectedPage';
import Go from '@util/CustomRedirect';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';
import { useUser } from '@util/providers/AuthProvider';
import { usePlan } from '@util/providers/Plan';
import useSubscription from '../../util/hooks/useSubscription';

export default function TrialStart() {
  const { t } = useTranslation();
  const { bmoUser } = useUser();
  const canTrial =
    bmoUser && bmoUser.subscriptionInfo
      ? bmoUser.subscriptionInfo.isTrialEligible
      : true;
  return (
    <ProtectedPage>
      <Rules>
        {canTrial ? (
          <>
            <SEO title={t('page:startTrial')} />
            <PageHeader className="mb-4">{t('page:startTrial')}</PageHeader>
            <ConfirmBody plan={'trial'} />
          </>
        ) : (
          <>
            <PageHeader className="mb-4">
              {t('page:yourTrialHasEnded')}
            </PageHeader>
            <p className="mb-1">{t('expired.expiredTrialText')}</p>
            <p>
              <small className="text-muted">
                {t('expired.dataAndProgress')}
              </small>
            </p>
            <div className="pt-2">
              <ViewAllPlansButton />
              <span className="pr-2"></span>
              <MyBoardmakerButton />
            </div>
          </>
        )}
      </Rules>
    </ProtectedPage>
  );
}

function Rules({ children }) {
  const { selectedPlan, selectedRate } = usePlan();
  const { bmoUser } = useUser();
  const { isTrialUser } = useSubscription();
  const homeRules =
    (selectedPlan?.key !== 'professional' &&
      selectedPlan?.key !== 'personal') ||
    !selectedRate?.isTrial ||
    bmoUser?.zuoraInfo ||
    isTrialUser;
  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}
