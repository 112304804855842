import React from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

export default function ViewAllPlansButton({ route = '/' }) {
  const { t } = useTranslation();
  return (
    <button
      className="btn btn-primary"
      type="button"
      id="ButtonViewAllPlans"
      onClick={() => {
        navigate(route);
      }}
    >
      {t('viewAllPlans')}
    </button>
  );
}
