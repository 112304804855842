import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

export default function MyBoardmakerButton() {
  const { t } = useTranslation();
  return (
    <button
      className="btn btn-outline-secondary"
      type="button"
      id="ButtonMyBoardmaker"
      onClick={() => {
        navigate('/redirect');
      }}
    >
      {t('myBoardmaker')}
    </button>
  );
}
